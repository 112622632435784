<template>
    <div class="container" id="CompetitionDetail">
        <el-page-header @back="$router.back()" :content="['创建赛事','编辑赛事','查看赛事信息','补充历史赛事'][type]"></el-page-header>
        <div class="CompetitionDetail_content">
            <el-alert
                title="审核不通过原因："
                :description="(editInfo.AuditReason || '')"
                type="warning"
                effect="dark"
                show-icon
                :closable="false"
                style="margin-bottom:30px"
                v-if="editInfo && editInfo.LeagueNowStatus == 6">
            </el-alert>
            <el-form ref="form" :model="form" :rules="rules" label-width="100px" size="small">
                <el-form-item label="运动项目:">{{form.eventsName}}</el-form-item>
                <el-form-item label="赛季名称:" prop="seasonName">
                    <el-input v-model.trim="form.seasonName" maxlength="10" placeholder="请输入赛季名称" style="width:100%;max-width:400px"></el-input>
                    <p style="line-height:20px;margin-top:5px;font-size:12px">示例：2022赛季 2022~2023赛季 2022年 2022年度 首届 第20届 第十五届</p>
                    <p style="line-height:20px;font-size:12px">注意：赛季名称不能重复。</p>
                </el-form-item>
                <el-form-item label="赛事全称:" prop="name">
                    <el-input v-model.trim="form.name" maxlength="50" placeholder="请输入赛事全称" style="width:100%;max-width:400px"></el-input>
                    <p style="line-height:20px;margin-top:5px;font-size:12px">可含有年度/赛季/届/举办地/赞助商等信息</p>
                </el-form-item>
                <el-form-item label="赛事简称:" prop="shortName">
                    <el-input v-model.trim="form.shortName" maxlength="32" placeholder="请输入赛事简称" style="width:100%;max-width:400px"></el-input>
                </el-form-item>
                <el-form-item label="比赛形式:" prop="type">
                    <el-radio-group v-model="form.type">
                        <el-radio :label="1" border>线上赛</el-radio>
                        <el-radio :label="2" border>线下赛</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="比赛平台url:" prop="leagueSite" v-if="form.type == 1">
                    <el-input v-model.trim="form.leagueSite" placeholder="请输入平台url" style="width:100%;max-width:400px"></el-input>
                </el-form-item>
                <el-form-item label="赛事logo:">
                    <div class="uploadImg logo">
                        <div class="img_box">
                            <div class="img" v-show="form.logoUrl || preLogoUrl">
                                <img :src="form.logoUrl || preLogoUrl">
                                <div class="state"></div>
                            </div>
                            <div class="add" @click="uploadImage('logoUrl')">
                                <i class="el-icon-plus"></i>
                            </div>
                        </div>
                        <div class="el-upload__tip">
                            <p>只能上传jpg/png格式，大小不超过 200KB</p>
                            <p>建议分辨率：42×42</p>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="封面照片:" prop="surfacePlotUrl" >
                    <div class="uploadImg surfacePlot">
                        <div class="img_box">
                            <div class="img" v-show="form.surfacePlotUrl || preSurfacePlotUrl">
                                <img :src="form.surfacePlotUrl || preSurfacePlotUrl">
                                <div class="state"></div>
                            </div>
                            <div class="add" @click="uploadImage('surfacePlotUrl')">
                                <i class="el-icon-plus"></i>
                            </div>
                        </div>
                        <div class="el-upload__tip">
                            <p>只能上传jpg/png格式，长宽比例：375×211，大小不超过 1MB</p>
                            <p>建议大小 0.5MB，分辨率：1080×608 或 1440×810</p>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="特殊状态:" prop="abnormalStatus">
                    <el-radio-group v-model="form.abnormalStatus">
                        <el-radio :label="0">无（正常）</el-radio>
                        <el-radio :label="1">赛事取消</el-radio>
                        <el-radio :label="2">赛事延期</el-radio>
                        <el-radio :label="3">赛事中断</el-radio>
                    </el-radio-group>
                </el-form-item>
                <template v-if="form.abnormalStatus > 0">
                    <el-form-item label="公告链接:" prop="bulletinLink">
                        <el-input v-model="form.bulletinLink" placeholder="请输入公告链接" style="width:100%;max-width:400px" @blur="bulletinLinkBlur"></el-input>
                    </el-form-item>
                    <el-form-item label="公告标题:" prop="bulletinTitle">
                        <el-input v-model="form.bulletinTitle" maxlength="50" placeholder="请输入公告标题" style="width:100%;max-width:400px"></el-input>
                    </el-form-item>
                </template>
                <el-form-item label="比赛时间:" :prop="form.abnormalStatus == 0 ? 'time' : ''">
                    <el-date-picker
                        v-model="form.time"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-ddTHH:mm:ss">
                    </el-date-picker>
                </el-form-item>
                <el-card shadow="never" style="width:100%;max-width:700px;box-sizing: border-box;margin-bottom:20px">
                    <el-form-item label="已添加地点:" label-width="130px" prop="addressList" :required="form.type == 2">
                        <div style="width:100%;max-width:500px">
                            <el-tag
                                v-for="(item,index) in form.addressList" :key="index"
                                disable-transitions effect="plain" closable
                                @close="removedAddress(index)" 
                            >
                                <span>{{item.RegionName}}</span>
                            </el-tag>
                        </div>
                    </el-form-item>
                    <el-form-item :required="form.type == 2" label-width="130px" style="margin-bottom:0">
                        <template slot="label">比赛地点:</template>
                        <div style="display:inline-block;width:calc(100% - 90px);max-width:400px">
                            <el-select v-model="addressForm.ProvinceCode" @change="getCity" placeholder="请选择省" style="width:33%;">
                                <el-option v-for="(item,index) in provinceList" :key="index" :value="item.province_id" :label="item.province"></el-option>
                            </el-select>
                            <el-select v-model="addressForm.CityCode" @change="getArea" placeholder="请选择市" style="width:33%;">
                                <el-option v-for="(item,index) in cityList" :key="index" :value="item.city_id" :label="item.city"></el-option>
                            </el-select>
                            <el-select v-model="addressForm.AreaCode" placeholder="请选择区县" style="width:33%;">
                                <el-option v-for="(item,index) in areaList" :key="index" :value="item.area_id" :label="item.area"></el-option>
                            </el-select>
                        </div>
                        <el-button type="primary" style="margin-left:10px" @click="addAddress()" title="点击添加比赛地点"><i class="el-icon-plus"></i> 添加</el-button>
                    </el-form-item>
                </el-card>
                <el-card shadow="never" style="width:100%;max-width:700px;box-sizing: border-box;margin-bottom:20px">
                    <el-tree 
                        :data="units" 
                        node-key="Kind" 
                        draggable
                        :allow-drop="handleAllowDrop" 
                        @node-drop="nodeDrop"
                    >
                        <template slot-scope="scope">
                            <el-form-item :label="`${scope.node.data.Kind}:`" :prop="`units['${scope.node.data.Kind}']`" :rules="getUnitRules(scope.node.data.Kind)" label-width="100px" style="margin-bottom: 0">
                                <div style="width:100%;max-width:500px;white-space: normal;">
                                    <el-tag
                                        v-for="(unitItem,unitIndex) in scope.node.data.Units" :key="unitIndex"
                                        disable-transitions effect="plain" closable
                                        @close="removeUnit(scope.node.data.Kind, unitIndex)" 
                                    >
                                        <span>{{unitItem}}</span>
                                    </el-tag>
                                </div>
                            </el-form-item>
                        </template>
                    </el-tree>
                    <el-form-item label-width="130px" style="margin-top:20px;margin-bottom:0">
                        <template slot="label">
                            <el-dropdown @command="unitTypeChange" :disabled="!visibleUnitTypes">
                                <el-input v-model="unitType" placeholder="自定义名称" style="width: 100px" :maxlength="4" @focus="visibleUnitTypes = false" @blur="visibleUnitTypes = true"></el-input>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item v-for="item in unitTypes" :key="item" :command="item">{{item}}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>:
                        </template>
                        <el-input v-model.trim="unitName" :placeholder="unitType + '名称'" style="width:calc(100% - 90px);max-width:400px"></el-input>
                        <el-button type="primary" style="margin-left:10px" @click="addUnit()"  :title="'点击添加' + unitType"><i class="el-icon-plus"></i> 添加</el-button>
                    </el-form-item>
                </el-card>
                <el-form-item label="赛事详情:" prop="detail">

                    <Editor v-model="form.detail" :oss-options="this.UploaderOptions"></Editor>
                </el-form-item>
                <el-divider></el-divider>
                <div style="margin-bottom:20px">赛事分组信息</div>
                <template v-if="form.eventsId">
                    <el-card shadow="never" v-for="(item,index) in groupEvents" :key="item.Id" style="width:100%;max-width:700px;box-sizing: border-box;margin-bottom:20px">
                        <el-form-item label="比赛项目:" label-width="100px" style="margin-bottom:0">
                            <el-row type="flex" justify="space-between">
                                <span>{{item.Name}}</span>
                                <el-button type="danger" @click="removeGroupEvents(index)" v-show="groupEvents.length > 1"><i class="el-icon-minus"></i> 删除此项目</el-button>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="已添加组别:" label-width="100px" style="width:100%;" prop="groups">
                            <el-tag
                                v-for="(groupItem,groupIndex) in handleGroups[item.Id]" :key="groupIndex"
                                disable-transitions effect="plain" closable style="cursor: pointer;"
                                @click="openGroup(item, groupItem)"
                                @close="removeGroup(groupItem)"
                            >
                                <span>{{groupItem.GroupName}}（{{getGroupTypeText(item, groupItem)}}）</span>
                            </el-tag>
                        </el-form-item>
                        <el-button type="primary" size="small" @click="openGroup(item)" style="margin-left:100px" title="点击添加组别"><i class="el-icon-plus"></i> 添加组别</el-button>
                        <el-form-item label="快捷添加:" style="width:100%;margin-top:10px;margin-bottom:0" v-if="handlePresetGroups[item.Id] && handlePresetGroups[item.Id].length > 0">
                            <div style="max-height:100px;overflow-y: auto;">
                                <el-button
                                    v-for="(groupItem,groupIndex) in handlePresetGroups[item.Id]" :key="groupIndex"
                                    plain style="margin-bottom: 5px"
                                    @click="selectGroup(item, groupItem)"
                                >
                                    <span>{{groupItem.GroupName}}（{{getGroupTypeText(item, groupItem)}}）</span>
                                </el-button>
                            </div>
                        </el-form-item>
                    </el-card>
                    <el-dropdown @command="addGroupEvents" v-if="handleEventsList.length > groupEvents.length">
                        <el-button type="primary" size="medium" style="width:150px"><i class="el-icon-plus"></i> 添加组别项目</el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="item in handleEventsList" :key="item.Id" :command="item" :disabled="groupEvents.some(eventsItem=>eventsItem.Id == item.Id)">{{item.Name}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-form>
            <el-divider></el-divider>
            <el-row style="margin:30px 0 20px 100px">
                <el-button type="success" style="width:100px" @click="save">保存</el-button>
            </el-row>
        </div>
        <el-dialog :title="groupInfo.groupInfo ? '修改组别信息' : '添加组别'" :visible.sync="groupInfoVisible" :close-on-click-modal="false" :destroy-on-close="true" width="500px" >
            <el-form ref="groupForm" :model="groupForm" :rules="groupRules" label-width="100px" size="small">
                <el-form-item label="组别名称:" prop="name">
                    <el-input v-model.trim="groupForm.name" maxlength="50" placeholder="请输入组别名称" style="width:100%;max-width:400px"></el-input>
                </el-form-item>
                <el-form-item label="组别类型:" prop="type" v-if="groupInfo.groupTypes">
                    <el-radio-group v-model="groupForm.type">
                        <el-radio v-for="(item,index) in groupInfo.groupTypes" :key="index" :label="item.value" border>{{item.label}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="groupInfoVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitGroup">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    GetEditSubleagueInitInfo,
    GetSubleagueDetail,
    CreateSubleague,
    EditSubleague
} from '@/api'
import {post} from '@/api/oldApi';
import Editor from '@/components/Editor';
import eventsConfig from './eventsConfig';
const defaultUnitTypes = ['主办单位', '指导单位', '支持单位', '承办单位', '运营单位', '协办单位', '赞助单位'];
const requiredUnits = ['主办单位', '承办单位'];
export default {
    components: { 
        Editor
    },
    data(){
        return {
            type: 0,//0：创建 1：修改 3：补充历史赛事
            subLeagueId: 0,
            seasonType: 0,
            editInfo: null,
            form:{
                seasonName: "",
                seasonId: 0,
                eventsId: null,
                eventsName: "",
                name: "",
                shortName: "",
                type: 2,
                leagueSite: "",
                logoUrl: "",
                surfacePlotUrl:"",
                abnormalStatus: 0,
                bulletinLink: "",
                bulletinTitle: "",
                time: [],
                addressList: [],
                detail:""
            },
            eventsList: [],
            preLogoUrl: "",
            preSurfacePlotUrl: "",
            addressForm: {
                ProvinceCode: undefined,
                CityCode: undefined,
                AreaCode: undefined
            },
            provinceList: [],
            cityList: [],
            areaList: [],
            units: [],
            unitTypes: [...defaultUnitTypes],
            unitType: defaultUnitTypes[0],
            unitName: '',
            visibleUnitTypes: true,
            groupEvents: [],
            groups: [],
            presetGroups: [],
            groupInfoVisible: false,
            groupInfo: {},
            groupForm:{
                name: '',
                type: ''
            },
            UploaderOptions:{},
            isOption: true
        }
    },
    created(){
        let subLeagueId = +(this.$route.params.subLeagueId || 0),
            type = +(this.$route.params.type || 0);
        this.subLeagueId = subLeagueId;
        this.type = type;
        this.getInitInfo();
        if([1, 2].includes(type)){
           this.getDetail();
        }
        this.getProvince();
        requiredUnits.forEach(item=>{
            this.units.push({
                Kind: item,
                Units: []
            })
        })
    },
    watch:{
        'form.detail':{
            deep:true,
            handler(){
                this.$nextTick(function(){
                    this.$refs['form'].validateField(`detail`);
                })
            }
        }
    },
    computed: {
        rules(){
            return {
                seasonName:[
                    {required:true,message:"请输入赛季全称",trigger: 'blur'},
                    {max:10,message:"赛季名称最长10个字符",trigger: 'blur'}
                ],
                eventsId:[
                    {required:true,message:"请选择运动项目",trigger: 'change'}
                ],
                name:[
                    {required:true,message:"请输入赛事名称",trigger: 'blur'},
                    {max:50,message:"赛事名称最长50个字符",trigger: 'blur'}
                ],
                shortName:[
                    {max:32,message:"赛事简称最长32个字符",trigger: 'blur'}
                ],
                type:[
                    {required:true,message:"请选择比赛形式",trigger: 'change'}
                ],
                surfacePlotUrl:[
                    {required:true,message:"请上传赛事封面"}
                ],
                abnormalStatus:[
                    {required:true,message:"请选择特殊状态"}
                ],
                bulletinLink:[
                     {validator:(rule, value, callback)=>{
                        if(value){
                            if(!(/^http|https/.test(value))){
                                callback(new Error('必须是一个以http或https开头的有效链接'));
                            }else{
                                callback();
                            }
                        }else{
                            callback();
                        }
                    }, trigger: 'blur'}
                ],
                bulletinTitle:[
                     {validator:(rule, value, callback)=>{
                        if(this.form.bulletinLink && !value){
                            callback(new Error('请输入公告标题'));
                        }else{
                            callback();
                        }
                    }, trigger: 'blur'},
                    {max:50,message:"公告标题最长50个字符",trigger: 'blur'}
                ],
                time:[
                    {required:true,validator:(rule, value, callback)=>{
                        if(!value || value.length == 0){
                            callback(new Error('请选择比赛时间'));
                        }else{
                            callback();
                        }
                    }, trigger: 'change'}
                ],
                addressList:[
                    {validator:(rule, value, callback)=>{
                        if(this.form.type == 2 && (!value || value.length == 0)){
                            callback(new Error('请添加比赛地点'));
                        }else{
                            callback();
                        }
                    }}
                ],
                detail:[
                    {required:true,message:"请输入赛事详情"}
                ],
                groups: [
                    {required: true, validator:(rule, value, callback)=>{
                        if(!this.groups || this.groups.length == 0){
                            callback('请添加组别');
                        }else{
                            callback();
                        }
                    }
                }]
            }
        },
        groupRules(){
            return {
                name:[
                    {required:true,message:"请输入组别名称",trigger: 'blur'},
                    {max:50,message:"组别名称最长50个字符",trigger: 'blur'}
                ],
                type:[
                    {required:true,message:"请选择组别类型",trigger: 'change'}
                ]
            }
        },
        handleEventsList(){
            let list = [];
            if([3, 8].includes(this.form.eventsId)){
                list = this.eventsList.filter(item=>![3, 8].includes(item.Id));
            }else{
                list = this.eventsList.filter(item=>this.form.eventsId == item.Id);
            }
            return list;
        },
        handleGroups(){
            let obj = {};
            this.handleEventsList.forEach(item=>{
                let list = this.groups.filter(groupItem=>groupItem.EventsId === item.Id);
                obj[item.Id] = list;
            })
            return obj;
        },
        handlePresetGroups(){
            let obj = {};
            this.handleEventsList.forEach(item=>{
                let list = this.presetGroups.filter(groupItem=>groupItem.EventsId === item.Id);
                obj[item.Id] = list;
            })
            return obj;
        }
    },
    methods:{
        getInitInfo(){
            GetEditSubleagueInitInfo({
                SubLeagueId: this.subLeagueId
            }).then(data=>{
                if(data.Code == 0){
                    this.eventsList = data.EventsList;
                    this.UploaderOptions = data.UploaderOptions;
                    this.presetGroups = data.PresetGroupList || [];
                    if([0, 3].includes(this.type)){
                        this.subLeagueId = data.NewSubLeagueId;
                        let obj = data.EventsList[0];
                        this.form.eventsId = obj.Id;
                        this.form.eventsName = obj.Name;
                        this.$nextTick(function(){
                            this.groupEvents = [this.handleEventsList[0]];
                        })
                    }
                }
            })
        },
        getDetail(){
             GetSubleagueDetail({
                SubLeagueId: this.subLeagueId
            }).then(data=>{
                if(data.Code == 0){
                    var editInfo = data.EditInfo;
                    this.editInfo = editInfo;
                    let form = {};
                    form.seasonName = editInfo.SeasonName;
                    form.seasonId = editInfo.SeasonId;
                    form.eventsId = editInfo.EventsId;
                    form.eventsName = editInfo.EventsName;
                    form.name = editInfo.LeagueFullName;
                    form.shortName = editInfo.LeagueShortName;
                    form.type = editInfo.IsOnline ? 1 : 2;
                    form.leagueSite = editInfo.LeagueSite;
                    form.logoUrl = editInfo.LogoUrl;
                    form.surfacePlotUrl = editInfo.CoverUrl;
                    form.abnormalStatus = editInfo.AbnormalStatus;
                    form.bulletinLink = editInfo.BulletinUrl;
                    form.bulletinTitle = editInfo.BulletinTitle;
                    form.addressList = editInfo.GamePlace || [];
                    form.time = editInfo.GameTime ? [editInfo.GameTime.BeginTime,editInfo.GameTime.EndTime] : [];
                    form.detail = editInfo.Comment;
                    this.form = form;
                    if(editInfo.AllUnits){
                        let units = [],
                            unitTypes = this.unitTypes,
                            unitType = unitTypes[0];
                        editInfo.AllUnits.forEach(item=>{
                            units.push({
                                Kind: item.Kind,
                                Units: [...item.Units]
                            })
                            if(!defaultUnitTypes.includes(item.Kind)){
                                unitTypes.push(item.Kind);
                            }
                        })
                        if(units.length){
                            unitType = units[0].Kind;
                        }
                        this.units = units;
                        this.unitTypes = unitTypes;
                        this.unitType = unitType;
                    }
                    if(editInfo.GroupList){
                        this.groups = editInfo.GroupList.map((item, index)=>({...item, extraId: index + 1}));//匹配临时使用
                        let groupEvents = [];
                        editInfo.GroupList.forEach(item=>{
                            if(!groupEvents.some(eventsItem=>eventsItem.Id == item.EventsId)){
                                groupEvents.push({
                                    Id: item.EventsId,
                                    Name: item.EventsName
                                })
                            }
                        })
                        this.groupEvents = groupEvents;
                    }
                }
            })
        },
        bulletinLinkBlur(){
            var value = this.form.bulletinLink;
            if((/^http|https/.test(value))){
                var _this = this,
                    iframe = document.createElement('iframe'),
                    is = true;
                iframe.id = 'iframe';
                iframe.src = value;
                iframe.style.display = "none";
                document.body.appendChild(iframe);
                window.addEventListener('message', function (e) {
                    if(!is)return;
                    _this.form.bulletinTitle = e.data;
                    document.body.removeChild(document.getElementById('iframe'));
                    is = false;
                })
                iframe.onload = function(){
                    if(!is)return;
                    if(iframe.contentWindow.document.title){
                        _this.form.bulletinTitle = iframe.contentWindow.document.title;
                        document.body.removeChild(document.getElementById('iframe'));
                        is = false;
                    }
                }
            }
        },
        nodeDrop(draggingNode, dropNode){
            let arr = [...this.unitTypes];
            let dragData = draggingNode.data,
                dropData = dropNode.data;
            arr.splice(dragData.index, 1);
            arr.splice(dropData.index, 0, dragData.label);
            this.unitTypes = arr;
        },
        handleAllowDrop(draggingNode, dropNode, type){
            if(type == 'inner') return false;
            return true;
        },
        unitTypeChange(type){
            this.unitType = type;
        },
        addUnit(){
            let unitType = this.unitType;
            if(this.unitName && unitType){
                let index = this.units.findIndex(item=>item.Kind == unitType);
                if(index >= 0){
                    let obj = this.units[index];
                    obj.Units.push(this.unitName);
                    this.$set(this.units, index, obj);
                }else{
                    this.units.push({
                        Kind: unitType,
                        Units: [this.unitName]
                    })
                }
                this.unitName = '';
                if(!this.unitTypes.includes(unitType)){
                    this.unitTypes.push(unitType);
                }
                if(requiredUnits.includes(unitType)){
                    this.$refs.form.validateField(`units['${unitType}']`);
                }
            }
        },
        removeUnit(unitType, index){
            let typeIndex = this.units.findIndex(item=>item.Kind == unitType);
            let obj = this.units[typeIndex];
            obj.Units.splice(index, 1);
            if(obj.Units.length == 0 && !requiredUnits.includes(unitType)){
                this.units.splice(typeIndex, 1);
            }else{
                this.$set(this.units, typeIndex, obj);
            }
            if(requiredUnits.includes(unitType)){
                this.$refs.form.validateField(`units['${unitType}']`);
            }
        },
        uploadImage(type){
            this.$file.cloose({
                accept: 'image/*'
            }).then(file=>{
                let acceptArr =  ['image/jpeg','image/png','image/bmp'];
                if(!acceptArr.includes(file.type)){
                    this.$message.error('不支持此文件类型');
                    return;
                }
                if (type == 'logoUrl' && file.size > 200*1024) {
                    this.$message.error('图片大小不能超过 200KB，请压缩后上传');
                    return;
                }else if (type == 'surfacePlotUrl' && file.size > 1*1024*1024) {
                    this.$message.error('图片大小不能超过 1MB，请压缩后上传');
                    return;
                }
                let imgSrc = URL.createObjectURL(file);
                let dom = "";
                if(type == 'logoUrl'){
                    this.form.logoUrl = '';
                    this.preLogoUrl = imgSrc;
                    dom = ".logo .state";
                }else {
                    this.form.surfacePlotUrl = '';
                    this.preSurfacePlotUrl = imgSrc;
                    dom = ".surfacePlot .state";
                }
                let loading = this.$loading({
                    target: dom,
                    lock: true,
                    text: '图片正在上传',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.8)'
                });
                this.$file.ossUpload({
                    file,
                    uploadOptions: this.UploaderOptions
                }).then(data=> {
                    loading.close();
                    if(type == 'logoUrl'){
                        this.form.logoUrl = data.url;
                        this.preLogoUrl = '';
                    }else{
                        this.form.surfacePlotUrl = data.url;
                        this.preSurfacePlotUrl = '';
                        this.$refs['form'].validateField('surfacePlotUrl');
                    }
                }).catch(()=>{
                    loading.close();
                    if(type == 'logoUrl'){
                        this.preLogoUrl = '';
                    }else{
                        this.preSurfacePlotUrl = '';
                    }
                    this.$message.error('图片上传失败，请重试！');
                });
            })
        },
        addAddress(){
            let {
                ProvinceCode,
                CityCode,
                AreaCode
            } = this.addressForm;
            if(ProvinceCode != undefined){
                let ProvinceName = this.provinceList.find(item=>item.province_id == ProvinceCode).province,
                    CityName = CityCode ? this.cityList.find(item=>item.city_id == CityCode).city : '',
                    AreaName = AreaCode ? this.areaList.find(item=>item.area_id == AreaCode).area : '';
                let RegionName = ProvinceName + (ProvinceName != CityName ? CityName : '') + AreaName;
                let list = this.form.addressList;
                list.push({
                    ProvinceCode,
                    CityCode,
                    AreaCode,
                    RegionName
                })
                this.addressForm = {
                    ProvinceCode: undefined,
                    CityCode: undefined,
                    AreaCode: undefined
                };
                this.$set(this.form,'addressList',list);
                this.$refs['form'].validateField('addressList');
            }
        },
        removedAddress(index){
            let list = this.form.addressList;
            list.splice(index, 1);
            this.$set(this.form, 'addressList', list);
            this.$refs['form'].validateField('addressList');
        },
        addGroupEvents(item){
            this.groupEvents.push(item);
        },
        removeGroupEvents(index){
            let obj = this.groupEvents[index];
            let list = this.groups.filter(item=>item.EventsId == obj.Id);
            if(!list || list.length == 0){
                this.groupEvents.splice(index, 1);
                this.$nextTick(function(){
                    this.$refs.form.validateField('groups');
                })
                return;
            }
            this.$confirm('请确认删除此项目').then(() => {
                this.groupEvents.splice(index, 1);
                let list = this.groups.filter(item=>item.EventsId != obj.Id);
                this.groups = list;
                this.$nextTick(function(){
                    this.$refs.form.validateField('groups');
                })
            })
        },
        openGroup(item, groupItem){
            let groupTypes = this.getGroupTypes(item);
            this.groupInfo = {
                eventsInfo: item,
                groupInfo: groupItem,
                groupTypes
            }
            if(groupItem){
                this.groupForm = {
                    name: groupItem.GroupName,
                    type: groupItem.GroupType
                }
            }else{
                this.groupForm = {
                    name: '',
                    type: groupTypes[0].value
                }
            }
            this.groupInfoVisible = true;
        },
        submitGroup(){
            this.$refs.groupForm.validate((valid)=>{
                if(valid){
                    let form = this.groupForm;
                    if(this.groupInfo.groupInfo){
                        let {
                            TempGroupId,
                            extraId
                        } = this.groupInfo.groupInfo;
                        let index = this.groups.findIndex(item=>(item.TempGroupId && item.TempGroupId == TempGroupId) || (item.extraId && item.extraId == extraId));
                        let isVip = ['嘉宾','干部'].some(item=>form.name.includes(item));
                        this.$set(this.groups, index, {
                            ...this.groupInfo.groupInfo,
                            GroupName: form.name,
                            GroupType: form.type,
                            IsHonored: +isVip
                        })
                        this.$message.success('修改成功');
                    }else{
                        let {
                            Id,
                            Name
                        } = this.groupInfo.eventsInfo;
                        let isVip = ['嘉宾','干部'].some(item=>form.name.includes(item));
                        this.groups.push({
                            "TempGroupId": 0,
                            "GroupName": form.name,
                            "EventsId": Id,
                            "EventsName": Name,
                            "LeagueId": this.subLeagueId,
                            "GroupType": form.type,
                            "IsHonored": +isVip,
                            extraId: Date.now()
                        })
                        this.$refs['form'].validateField('groups');
                        this.$message.success('添加成功');
                    }
                    this.groupInfoVisible = false;
                }
            })
        },
        removeGroup(groupItem){
            let index = this.groups.findIndex(item=>(item.TempGroupId && item.TempGroupId == groupItem.TempGroupId) || (item.extraId && item.extraId == groupItem.extraId));
            this.groups.splice(index, 1);
            this.$refs['form'].validateField('groups');
        },
        selectGroup(item, groupItem){
            let {
                Id,
                Name
            } = item;
            this.groups.push({
                "TempGroupId": 0,
                "GroupName": groupItem.GroupName,
                "EventsId": Id,
                "EventsName": Name,
                "LeagueId": this.subLeagueId,
                "GroupType": groupItem.GroupType,
                "IsHonored": groupItem.IsHonored,
                extraId: Date.now()
            })
            this.$refs['form'].validateField('groups');
        },
        save(){
            if(this.unitName && this.unitType){
                this.addUnit();
            }
            this.$refs['form'].validate((valid)=>{
                if(valid){
                    let form = this.form;
                    let SeasonType = 0;
                    switch (this.type){
                        case 0:
                            SeasonType = 2;
                            break;
                        case 1:
                            SeasonType = this.editInfo.SeasonType;
                            break;
                        case 3:
                            SeasonType = 3;
                            break;
                    }
                    let GroupList = [];
                    this.groupEvents.forEach(item=>{
                        let list = this.groups.filter(groupItem=>groupItem.EventsId === item.Id);
                        GroupList.push(...list);
                    })
                    GroupList = GroupList.map(({extraId, ...info}, index)=>({...info, OrderNo: index + 1}))
                    let Units = this.units.filter(item=>item.Units && item.Units.length);
                    let sendData = {
                        "EditInfo": {
                            "MainLeagueId": this.$store.state.leagueInfo.id,
                            "SubleagueId": this.subLeagueId,
                            "SeasonType": SeasonType,
                            "SeasonId": form.seasonId,
                            "SeasonName": form.seasonName,
                            "EventsId": form.eventsId,
                            "EventsName": form.eventsName,
                            "LeagueFullName": form.name,
                            "LeagueShortName": form.shortName,
                            "IsOnline": form.type == 1,
                            "LeagueSite": form.leagueSite,
                            "LogoUrl": form.logoUrl,
                            "CoverUrl": form.surfacePlotUrl,
                            "AbnormalStatus": form.abnormalStatus,
                            "BulletinUrl": form.bulletinLink,
                            "BulletinTitle": form.bulletinTitle,
                            "GamePlace": form.addressList,
                            "GameTime": {
                                "BeginTime": form.time ? form.time[0] || '2099-12-31' : '2099-12-31',
                                "EndTime":  form.time ? form.time[1] || '2099-12-31' : '2099-12-31'
                            },
                            "AllUnits": Units,
                            "Comment": form.detail,
                            "GroupList": GroupList
                        }
                    }
                    let loading = this.$loading({
                        text:"保存中...",
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    if([0, 3].includes(this.type)){
                        CreateSubleague(sendData).then(data=>{
                            loading.close();
                            if(data.Code == 0){
                                this.$message.success('赛事创建成功，2秒后返回列表');
                                setTimeout(()=>{
                                    this.$router.back();
                                },1500)
                            }
                        }).catch(()=>{
                            loading.close();
                        })
                    }else{
                        EditSubleague(sendData).then(data=>{
                            loading.close();
                            if(data.Code == 0){
                                this.$message.success('赛事修改成功，2秒后返回列表');
                                setTimeout(()=>{
                                    this.$router.back();
                                },1500)
                            }
                        }).catch(()=>{
                            loading.close();
                        })
                    }
                }else{
                    this.$message.error('填写信息有误，请查看！');
                }
            })
        },
        //获取省
        getProvince() {
            post('Match/Entrance', {
                action: 'Getprovince',
                user_token: ''
            }).then((data) => {
                if(data){
                    this.provinceList = data;
                }
            })
        },
        //获取市
        getCity() {
            if(this.addressForm.ProvinceCode == undefined){
                this.cityList = [];
                this.areaList = [];
                this.addressForm.CityCode = undefined;
                this.addressForm.AreaCode = undefined;
                return;
            }
            post('Match/Entrance', {
                action: 'Getcity',
                user_token: '',
                id: this.addressForm.ProvinceCode
            }).then((data) => {
                if(data){
                    this.cityList = data.splice(1);
                    this.areaList = [];
                    this.addressForm.CityCode = undefined;
                    this.addressForm.AreaCode = undefined;
                }
            })
        },
        getArea() {
            post('Match/Entrance', {
                action: 'Getarea',
                user_token: '',
                id: this.addressForm.CityCode
            }).then((data) => {
                if(data){
                    this.areaList = data.splice(1);
                    this.addressForm.AreaCode = undefined;
                }
            })
        },
        getUnitRules(unitType){
            let units = this.units;
            return requiredUnits.includes(unitType) ? [
                {required: true, validator:(rule, value, callback)=>{
                    let obj = units.find(item=>item.Kind == unitType);
                    let list = obj.Units || [];
                    if(list.length == 0){
                        callback('请添加' + unitType);
                    }else{
                        callback();
                    }
                }}
            ] : null;
        },
        getGroupTypes(eventsItem){
            let obj = eventsConfig.find(item=>item.Id == eventsItem.Id);
            if(obj && obj.groupTypes){
                return obj.groupTypes;
            }else{
                return [
                    { value: 1, label: '个人' },
                    { value: 2, label: '团体' },
                    { value: 3, label: '双人组合' }
                ]
            }
        },
        getGroupTypeText(eventsItem, groupItem){
            let groupTypes = this.getGroupTypes(eventsItem);
            return (groupTypes.find(item=>item.value == groupItem.GroupType) || {}).label + (groupItem.IsHonored ? '，嘉宾' : '');
        }
    }
}
</script>
<style scoped>
    .el-tree::v-deep .el-tree-node__content{
        height: auto;
    }
    .el-tree::v-deep .el-form-item{
        margin-bottom: 0;
    }
</style>
<style>
    
#CompetitionDetail .el-card .el-card__body{
    padding: 10px !important;
}
#CompetitionDetail .CompetitionDetail_editor .tox-editor-header{
    display: none ;
}

#CompetitionDetail .el-tag{
    margin-right:10px;
    margin-bottom:5px;
    max-width: 100%;
}
#CompetitionDetail .el-tag>span{
    width:calc(100% - 10px);
    display: inline-block;
    /* overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap; */
    vertical-align: top;
}
#CompetitionDetail .el-tag>i{
    margin-left: -5px;
}
.el-upload__tip{
    line-height: 2em;
}
.unit-popper li{
    padding: 0 10px;
}
</style>